import { render, staticRenderFns } from "./WaitingQuote.vue?vue&type=template&id=5189e8d8&scoped=true&"
import script from "./WaitingQuote.vue?vue&type=script&lang=js&"
export * from "./WaitingQuote.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/review/newTicket.css?vue&type=style&index=0&id=5189e8d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5189e8d8",
  null
  
)

export default component.exports